<template>
  <div>
    <Navigation />
    <Dashboard />
    <Footeur />
  </div>
</template>

<script>

export default {
  
    components:{
        Navigation: () => import("./Desktop/Includes/Nav.vue"),
        Footeur: () => import("./Desktop/Includes/Footer.vue"),
        Dashboard: () => import("./Desktop/Includes/Dashboard.vue"),
    },
    created(){
      if(!this.$store.state.ngayi.email) {
        this.$router.push("/login");
      }
    },
    methods:{

    }
}
</script>

<style>

</style>